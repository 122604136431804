import { ToastContainer } from "react-toastify";
import { Button } from "./button";
import { BlackLine, EllipseBackground } from "./ellipse_background";
import { useNavigate } from "react-router-dom";

export default function ConfirmedTransaction() {
    const navigate = useNavigate();

    const handleMoreOperation = () => {
        navigate('/transaction/vender');
    }

    return (
        <div className="relative w-full h-screen flex justify-center items-center overflow-hidden">
            <EllipseBackground />
            <ToastContainer theme="dark" />
            <div className="w-full max-w-[514px] flex flex-col items-center gap-3 bg-white rounded-xl mt-2 py-8 px-14 z-50">
                <h2 className="text-yellow-500 font-bold text-2xl">Operação concluída com sucesso!</h2>
                <p className="text-textBlack-100 text-center">Você receberá no seu WhatsApp um <br /> comprovante da operação.</p>
                <div className="flex items-center justify-center my-4">
                    <img
                        src="/checked.png"
                        alt="Imagem de check"
                    />
                </div>

                <Button
                    text="Fazer mais operações"
                    isLoading={false}
                    onClick={handleMoreOperation}
                />
            </div>
        </div>
    )
}